/* global

ga

*/


export const Utils = (() => {

	const getParameterByName = function (name) {
		var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
		return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
	};

	const isMobileDevice = function () {
		return (/iPhone|iPod|iPad|Android|BlackBerry/).test(navigator.userAgent);
	};

	const isNumber = function (n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	};

	const sendAnalyticsPage = function (page) {
		if (ga !== undefined) {
			ga('send', 'pageview', page);
			if (document.location.hostname.search("rotate123.com") === -1) {
				console.log("analytics: " + page);
			}
		}
	};

	const getCookie = (key) => {
		if (document.cookie.length > 0) {
			var p1 = document.cookie.indexOf(key + "=");
			var p2 = 0;

			if (p1 >= 0) {
				p1 = p1 + key.length + 1;
				p2 = document.cookie.indexOf(";", p1);

				if (p2 == -1) {
					p2 = document.cookie.length;
				}

				return decodeURIComponent(document.cookie.substring(p1, p2));
			}
		}

		return "";
	};

	const setCookie = (key, val, days, dompath) => {
		var expdays = (days !== undefined) ? days : 365 * 100;
		var expdate = new Date();
		var path    = (dompath !== undefined) ? dompath : "/";
		expdate.setDate(expdate.getDate() + expdays);
		document.cookie = key + "=" + encodeURIComponent(val) + ";expires=" + expdate.toGMTString() + ";path=" + path;
	};

	const checkEmail = (em) => {

		// Check for one @ sign
		var parts = em.split("@");
		if (parts.length != 2) {
			return false;
		}

		// Get the mailbox and domain
		var box = parts[0];
		var dom = parts[1];

		// Check for a legal mailbox
		var fBox = /^[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+$/i;
		if (! fBox.test(box)) {
			return false;
		}

		// Check for a legal domain
		var fDom = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;
		if (! fDom.test(dom)) {
			return false;
		}

		// Default Return
		return true;
	};

	const strip = (s) => {
		var startix = 0;
		var endix   = s.length;
		var i;

		for (i = 0; i < s.length; i++) {
			if ((s[i] == ' ') || (s[i] == '\r') || (s[i] == '\n') || (s[i] == '\t')) {
				startix = i + 1;
			} else {
				break;
			}
		}

		for (i = s.length-1; i >= 0; i--) {
			if ((s[i] == ' ') || (s[i] == '\r') || (s[i] == '\n') || (s[i] == '\t')) {
				endix = i;
			} else {
				break;
			}
		}

		if (startix <= endix) {
			return s.substr(startix, endix);
		} else {
			return "";
		}
	};

	const hideBlock = (elem) => {
		elem.style.display = "none";
	};

	const showBlock = (elem, w, h) => {
		elem.style.display		= "block";
		elem.style.visibility	= "visible";

		if (w !== undefined) {
			elem.style.width = ipx(w);
		}

		if (h !== undefined) {
			elem.style.height = ipx(h);
		}
	};

	const decToHex = (d, padding) => {
		var hex = Number(d).toString(16);

		if (padding === undefined) {
			padding = 6;
		}

		while (hex.length < padding) {
			hex = "0" + hex;
		}

		return hex;
	};

	const e = (x) => {
		return document.getElementById(x);
	};

	const keyPress = (ev, validChars) => {
		if ((validChars === undefined) || (validChars === "") || (validChars === null)) {
			return true;
		}

		var keynum;

		if (window.event) {
			keynum = window.event.keyCode; // IE
		} else if (ev.which) {
			keynum = ev.charCode; // Firefox
		} else {
			return true;
		}

		var key = String.fromCharCode(keynum);

		if ((validChars.indexOf(key) >= 0) || (keynum === 0)) {
			return true;
		} else {
			return false;
		}
	};

	const ipx = (i) => {
		return i + "px";
	};

	const pxi = (px) => {
		return parseInt(px.replace("px", ""), 10);
	};

	const loadArray = (aa, bb) => {
		for (var i = 0; i < aa.length; i++) {
			if (i < bb.length) {
				if (aa[i] instanceof Array) {
					loadArray(aa[i], bb[i]);
				} else {
					aa[i] = bb[i];
				}
			}
		}
	};

	const sumWidths = (elems) => {
		var sum = 0;

		for (var i = 0; i < elems.length; i++) {
			if (elems[i]) {
				if (elems[i].style.display) {
					if (elems[i].style.display != "none") {
						sum += (elems[i].style.width)           ? pxi(elems[i].style.width)               : 0;
						sum += (elems[i].style.marginLeft)      ? pxi(elems[i].style.marginLeft)          : 0;
						sum += (elems[i].style.marginRight)     ? pxi(elems[i].style.marginRight)         : 0;
						sum += (elems[i].style.paddingLeft)     ? pxi(elems[i].style.paddingLeft)         : 0;
						sum += (elems[i].style.paddingRight)    ? pxi(elems[i].style.paddingRight)        : 0;
						sum += (elems[i].style.borderThickness) ? pxi(elems[i].style.borderThickness) * 2 : 0;
					}
				}
			}
		}

		return sum;

	};

	const absX = (obj, sum) => {
		if (sum === undefined) {
			sum = obj.offsetLeft;
		} else {
			sum += obj.offsetLeft;
		}

		if (obj.parentNode !== null) {
			if (obj.parentNode.offsetLeft !== undefined) {
				return absX(obj.parentNode, sum);
			}
		}

		return sum;
	};

	const absY = (obj, sum) => {
		if (sum === undefined) {
			sum = obj.offsetTop;
		} else {
			sum += obj.offsetTop;
		}

		if (obj.parentNode !== null) {
			if (obj.parentNode.offsetTop !== undefined) {
				return absY(obj.parentNode, sum);
			}
		}

		return sum;
	};

	const getElementsByClass = (cl) => {
		var classElements = [];
		var regex = new RegExp("(^|\\s)" + cl + "(\\s|$)");
		var elements = document.getElementsByTagName("*");
		for (var i=0, j=0; i < elements.length; i++) {
			if (regex.test(elements[i].className)) {
				classElements[j] = elements[i];
			}
		}
		return classElements;
	};

	// returns true if the text contains only ASCII
	const isAscii = (text) => {

		for (var i=0; i < text.length; i++) {
			var charCode = text[i].charCodeAt(0);
			if ((charCode >= 32 && charCode <= 127) || charCode == 10) {
				// valid
			} else {
				return false;
			}
		}

		return true;
	};

	const processError = (rsp) => {
		let error = {};
		if (rsp.responseJSON && rsp.responseJSON.code && rsp.responseJSON.message) {
			// this is Pyramid's default handling
			error['code'] = rsp.responseJSON.title;
			error['detail'] = Utils.strip(rsp.responseJSON.message);
			error['status'] = parseInt(rsp.responseJSON.code, 10);
		} else if (rsp.responseJSON && rsp.responseJSON.code) {
			// this is our in-house error sytle
			error['code'] = rsp.responseJSON.code;
			error['detail'] = rsp.responseJSON.detail || rsp.responseJSON.code;
			error['status'] = rsp.responseJSON.status;
		} else {
			// this is default handling
			error['code'] = rsp.statusText;
			error['detail'] = rsp.statusText;
			error['status'] = rsp.status;
		}
		return error;
	};

	const unique = (list) => {
		var result = [];
		$.each(list, function(i, el) {
			if ($.inArray(el, result) === -1) {
				result.push(el);
			}
		});
		return result;
	};

	return {
		getParameterByName : getParameterByName,
		isMobileDevice     : isMobileDevice,
		isNumber           : isNumber,
		sendAnalyticsPage  : sendAnalyticsPage,
		getCookie          : getCookie,
		setCookie          : setCookie,
		checkEmail         : checkEmail,
		strip              : strip,
		unique             : unique,

		// newly added, decided whether to keep (and move above this comment)
		// or to deprecated
		absX               : absX,
		absY               : absY,
		decToHex           : decToHex,
		e                  : e,
		getElementsByClass : getElementsByClass,
		ipx                : ipx,
		pxi                : pxi,
		loadArray          : loadArray,
		keyPress           : keyPress,
		hideBlock          : hideBlock,
		showBlock          : showBlock,
		sumWidths          : sumWidths,
		isAscii            : isAscii,
		processError       : processError,
	};

})();
