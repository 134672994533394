
$.extend({
	alert: function (message, title) {
		$("<div class='alert-dialog'></div>").dialog( {
			buttons: { Ok: function () { $(this).dialog("close"); } },
			close: function () { $(this).remove(); },
			open: function () {
				$(this).keypress(function(e) {
					if (e.keyCode == $.ui.keyCode.ENTER) {
						$(this).dialog("close");
					}
				});
			},
			resizable: false,
			title: title,
			modal: true
		}).text(message).height(24 + 24*message.length/30);
	}
});

$.extend({
	confirm: function(message, title, okAction) {
		$("<div class='confirm-dialog'></div>").dialog({
			buttons: {
				"Ok": function() {
					$(this).dialog("close");
					okAction();
				},
				"Cancel": function() {
					$(this).dialog("close");
				}
			},
			close: function() { $(this).remove(); },
			open: function() {
				$(".ui-dialog-titlebar-close").hide();
			},
			resizable: false,
			title: title,
			modal: true
		}).text(message).height(24 + 24*message.length/30);
	}
});
